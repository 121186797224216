<template>
	<section class="domain_content">
		<!-- privacy policy -->
		<div class="sub-page">
			<h1>Privacy Policy</h1>
			<p class="small">Last updated: August 22, 2024</p>
			<p>
				This Privacy Policy describes Our policies and procedures on the collection, 
				use and disclosure of Your information when You use the Service and tells You
				about Your privacy rights and how the law protects You.<br>
				We use Your Personal data to provide and improve the Service. By using the Service,
				You agree to the collection and use of information in accordance with this Privacy Policy.
			</p>
			<div class="part">
				<h2>
				Collecting and Using Your Personal Data
				</h2>
				<h3>
				Types of Data Collected
				</h3>
				<h5>
				Usage Data
				</h5>
				<p>
				Usage Data is collected automatically when using the Service. <br>
				Usage Data may include information such as Your Device's Internet Protocol address (e.g. IP address),
				browser type, browser version, the pages of our Service that You visit, the time and date of Your visit,
					the time spent on those pages, unique device identifiers and other diagnostic data. <br>
				When You access the Service by or through a mobile device, We may collect certain information automatically,
				including, but not limited to, the type of mobile device You use, Your mobile device unique ID, 
				the IP address of Your mobile device, Your mobile operating system, the type of mobile Internet browser You use, 
				unique device identifiers and other diagnostic data. <br>
				We may also collect information that Your browser sends whenever You visit our Service or when You access
				the Service by or through a mobile device. <br>
				</p>
				<h3>
				Security of Your Personal Data
				</h3>
				<p>
				The security of Your Personal Data is important to Us, but remember that no method of transmission over the Internet,
				or method of electronic storage is 100% secure. While We strive to use commercially acceptable means to protect Your
					Personal Data, We cannot guarantee its absolute security.
				</p>
				<h2>
				Links to Other Websites
				</h2>
				<p>
				Our Service may contain links to other websites that are not operated by Us. If You click on a third party link, 
				you will be directed to that third party's site. We strongly advise You to review the Privacy Policy of every site You visit. <br>
				We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.
				</p>
				<h2>
				Changes to this Privacy Policy
				</h2>
				<p>
				We may update Our Privacy Policy from time to time. You are advised to review this Privacy Policy periodically for any changes.
				Changes to this Privacy Policy are effective when they are posted on this page.
				</p>
			</div>
		</div>
	</section>
</template>
  
  <script>
import '@/css/main.scss'
const title = 'Privacy Policy'
const description = 'Our Privacy Policy explains how we deal with users\'data while you download sources or read blogs in this website.'
export default {
	metaInfo: {
		title,
		meta: [
			{
				name: 'description',
				content: description,
			},
			{
				name: 'title',
				content: title,
			},
			{
				property: 'og:title',
				content: title,
			},
			{
				property: 'og:description',
				content: description,
			},
		],
	},
}
</script>
  